@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'MarkPro-Bold SKY';
  src: url('./assets/fonts/MarkPro-Bold.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'MarkPro-Book SKY';
  src: url('./assets/fonts/MarkPro-Book.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'MarkPro-Black SKY';
  src: url('./assets/fonts/MarkPro-Black.ttf') format('truetype');
  font-display: swap;
}

@layer utilities {
  .lozenge {
    clip-path: polygon(7% 0, 100% 0, 91% 100%, 0 100%);
  }
}

body {
  margin: 0;
  font-family: 'MarkPro-Book SKY', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

#_root {
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

b {
  font-family: 'MarkPro-Bold SKY';
  font-weight: normal;
}

.info-text,
.main-content,
.current-highlight {
  opacity: 0;
  transition: opacity 0.3s;
}

.info-text.show,
.main-content.show,
.current-highlight.show {
  opacity: 1;
  transition: opacity 0s;
}

.slide {
  padding-bottom: 10px;
  margin-left: 4px;
}

.slide .bg-white {
  height: 600px !important;
}


.brightcove-react-player-loader video-js {
  width: 100vw;
  height: 56.25vw;
}

.brightcove-react-player-loader video-js iframe {
  width: 100%;
  height: 100%;
}

.product-slider .slick-track {
  display: flex;
}

.product-slider .slick-slide {
  height: inherit !important;
  margin-right: 0px;
}
.product-slider .slick-disabled {
  opacity: .1;
}
.product-slider .slick-slide > div,
.product-slider .slick-slide > div > div,
.product-slider .slick-slide > div > div > div {
  height: 100%;
}

.large-gallery .slick-dots button::before {
  color: white !important;
  font-size: 10px;
  margin-top: 10px;
}

.large-gallery .slick-slide {
  height: 1300px;
}

.section-card-panel h2 b {
  background-image: linear-gradient(110.93deg, #F26522 0%, #F96DB1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.pinch-panel {
  transition: all .5s;
}

.pinch-panel:hover {
    transform: scale(.96);
    transform-origin: center center;
    transition: all .5s;
}

#terms h5 {
  font-size: 20px;
  font-family: 'MarkPro-Black SKY';
  margin: 30px 0 10px 0
}

.radial-nsb {
  background: radial-gradient(67.7% 72.09% at 50.09% 50.09%, #601DB4 0%, rgba(0, 1, 58, 0.0001) 100%)
}

.top-header-bg {
  background: radial-gradient(90.28% 100% at 51.39% 0%, #0c97e4 0%, #163ea7 60.47%, #0c2277 79%, #01003d 100%);
  background: radial-gradient(90.28% 100% at 51.39% 0%, #163ea7 60.47%, #0c2277 79%, #01003d 100%);
}

.extras-description div p {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  font-family: 'MarkPro-Book SKY' !important;
}

.secondary-description h6 {
  font-family: 'MarkPro-Book SKY';
  font-size: 12px;
  font-style: normal;
  font-weight: 450;
  line-height: 15px;
}

.sky-pod-banner-description div p {
  font-family: 'MarkPro-Book SKY';
  font-size: 20px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
}

.slider-container {
  position: relative;
}

.slider-container video {
  z-index: 1; /* Ensure video is on top */
}

.slider-container img {
  z-index: 0; /* Ensure image is behind video */
}

.potrait-rail-cards .slick-list {
  padding-top: 10px;
  padding-bottom: 10px;
}