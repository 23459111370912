/***************************************
*
*   Overrides to cater for v1.9 tailwind
*
***************************************/

body,
#root {
  overflow-x: hidden;
}

.purple-gradient {
  background: linear-gradient(180deg, #0d1137 -116.59%, #364d9e -4.73%, #812dcd 99.54%);
}

.grey-gradient {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) -2%, rgba(224, 224, 224, 0.64) 98%);
}

.green-gradient {
  background: linear-gradient(90deg, #0baa4b 0%, #29c4ee 185.6%);
}

.black-gradient {
  background: linear-gradient(to bottom, transparent 0%, black 100%);
}

.text-44px {
  font-size: 44px !important;
  line-height: 42px !important;
}

.text-44spx {
  font-size: 44px !important;
  line-height: 42px !important;
}

.text-32px {
  font-size: 32px !important;
  line-height: 36px !important;
}

.h-530 {
  height: 530px;
}
.lg\:h-385 {
  height: 385px;
}

.text-235 {
  font-size: 235px;
  line-height: 235px;
}

.wh-channels {
  width: 300px;
  height: 169px;
  object-fit: 'cover';
}

.stretchfull {
  position: relative;
  margin-left: -20px;
  margin-right: -20px;
}

.text-bubbablue {
  color: #2ac4f3;
}
.text-250 {
  font-size: 234px;
  font-family: 'MarkPro-Extra-Light SKY';
}

.wh-310px {
  width: 310px;
  height: 310px;
}

.w-grid {
  width: 100%;
  max-width: 660px;
}

.scroll-panel {
  opacity: 0;
  transition: all 0.3s;
}

.scroll-panel.show {
  opacity: 1;
  transition: all 0.3s;
}

/************************************************
*
*   Slick Slider - Custom hence the !importants
*
************************************************/

/*.slick-list {
  height: 220px !important;
}
.slick-track {
  height: 220px !important;
} */
.slick-arrow {
  position: absolute;
  top: 50% !important;
  transform: translateY(-50%) !important;
  left: -70px !important;
  width: 58px !important;
  height: 58px !important;
  background: url('https://static.sky.co.nz/sky/roi/newskybox/generic-images/icons/icon_left_arrow.svg') top left
    no-repeat !important;
  font-size: 0 !important;
  content: ' ' !important;
}

.slick-next {
  display: none !important;
  right: 50px !important;
  left: auto !important;
  background: url('https://static.sky.co.nz/sky/roi/newskybox/generic-images/icons/icon_right_arrow.svg') top left
    no-repeat !important;
}
.slick-next:before {
  display: none !important;
  content: ' ' !important;
}


.slick-prev:before {
  content: ' ' !important;
}

.height-scroll {
  height: 80vh;
  max-height: 750px;
  background: #01001f;
}

img.height-scroll {
  height: 94vh;
  max-height: 750px;
}

.scroll-down {
  position: absolute;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  z-index: 100;
  top: 705px;
  margin-top: 40px;
}

.scroll-down svg {
  margin: 0 auto;
  width: 40px;
  height: 40px;
}

@media only screen and (max-width: 810px) {
  .height-scroll {
    height: 90vh;
    max-height: 100%;
  }
  img.height-scroll {
    height: 50vh;
  }
  .scroll-down {
    top: 88vh;
    margin-top: 32px;
  }
  .scroll-down svg {
    width: 34px;
    height: 34px;
  }
}

@media only screen and (max-width: 1024px) {
  .height-scroll {
    height: 92vh;
    max-height: 100%;
  }
  .scroll-down {
    top: 88vh;
    margin-top: 32px;
  }
  .scroll-down svg {
    width: 34px;
    height: 34px;
  }
  .slick-arrow,
  .slick-next,
  .slick-prev {
    display: none;
  }
  .text-44px {
    font-size: 32px !important;
    line-height: 36px !important;
  }
}

/****************************************
*
*   Salesforce Chat
*
****************************************/

.embeddedServiceHelpButton {
  opacity: 0;
  z-index: -1;
}

.embeddedServiceHelpButton.open {
  opacity: 1;
  z-index: 10;
}

.embeddedServiceHelpButton .helpButton .uiButton {
  background-color: #00b1eb;
  font-family: 'Arial', sans-serif;
}
.embeddedServiceHelpButton .helpButton .uiButton:focus {
  outline: 1px solid #00b1eb;
}

.dockableContainer {

}


/****************************************
*
*   Video
*
****************************************/

.video-inset {
  position: relative;
  width: calc(100vw - 0.75rem);
  height: calc(56.25vw - 0.75rem);
  overflow: hidden;
  max-height: 100vh;
}

.video-inset .brightcove-react-player-loader, 
.video-inset .brightcove-react-player-loader video-js {
  position: relative;
  width: 100%;
  height:  calc(56.25vw - 0.75rem);
  max-height: 100vh;
}

.video-inset .brightcove-react-player-loader, 
.video-inset .brightcove-react-player-loader video-js video {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover; 
  max-height: 100vh;
}

.video-background .brightcove-react-player-loader {
  width: 100vw;
  height: 675px;
}

.video-background .brightcove-react-player-loader video-js {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 900px;
  position: relative;
  object-fit: cover;
}

.video-background .brightcove-react-player-loader video-js .vjs-control-bar,
.video-background .brightcove-react-player-loader video-js .vjs-big-play-button,
.video-inset .brightcove-react-player-loader video-js .vjs-control-bar,
.video-inset .brightcove-react-player-loader video-js .vjs-big-play-button,
.video-testimonials .brightcove-react-player-loader video-js .vjs-control-bar,
.video-testimonials .brightcove-react-player-loader video-js .vjs-big-play-button {
  display: none;
}

.video-background .brightcove-react-player-loader video-js video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.video-testimonials .brightcove-react-player-loader video-js {
  width: 100%;
  height: 56.25vw;
  max-height: 720px;
}

.video-testimonials .brightcove-react-player-loader video-js iframe {
  width: 100%;
  height: 100%;
}


@media only screen and (max-width: 767px) {
  .video-background .brightcove-react-player-loader {
    height: 900px;
  }
  .video-inset {
    width: 100vw;
    height: 56.2vw;
    min-height: auto;
    margin-bottom: 60px;
  }
  .video-inset .brightcove-react-player-loader video-js {
    min-height: auto;
  }
}
/****************************************
*
*   Tech specs
*
****************************************/

.tech-image {
  max-height: 400px;
  margin-bottom: 20px;
  max-width: 650px;
}

@media only screen and (max-width: 768px) {
  .tech-image {
    max-height: 380px;
    margin-bottom: 20px;
    max-width: 600px;
  }
  .tech-image.small {
    height: 100%;
    max-height: 100% !important;
    width: calc(100% - 2rem)  !important;
    margin-bottom: 20px;
    max-width: 380px !important;
    object-fit: contain;
  }
}



.largest-text {
  font-size: 74px;
  line-height: 80px;
}

.teal-text {
  color: #4CBFC8;
}


/****************************************
*
*   Videos
*
****************************************/


.video-play {
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
}

.video-thumb {
  transition: .7s;
  cursor: pointer;
}


.video-hover {
  transition: all .5s;
}

.video-hover.open {
  height: 219px !important;
  width: 300px !important;
  transition: all .5s;
}

.video-preview  {
  height: 118px !important;
  width: 206px !important;
  opacity: 0;
  transition: all .5s;
  top: 72px;
}

.video-preview.open {
  height: 219px !important;
  width: 300px !important;
  max-height: 300px !important;
  opacity: 1;
  transition: all .5s;
  top:0;
}

.video-preview .button-style {
  height: 25px !important;
  width: 80px !important;
  padding: 0;
}

.video-preview .brightcove-react-player-loader {
  width: 100%;
  height: 100%;
  height: 100px !important;
}

.video-preview .brightcove-react-player-loader .video-js {
  width: 100%;
  height: 100%;
  border-radius: 0.3rem 0.3rem 0 0;
  min-height: 170px;
}

.video-preview .brightcove-react-player-loader .video-js .vjs-control-bar,
.video-inset .brightcove-react-player-loader .video-js .vjs-control-bar,
.video-preview .brightcove-react-player-loader .video-js .vjs-big-play-button {
display: none;
}

.video-preview.open .brightcove-react-player-loader .video-js {
  border-radius: 0.3rem 0.3rem 0 0;
}

video::-moz-picture-in-picture-button {
  display: none;
}

.pip-expanded, .pip-small, .pip-icon, .pip-explainer {
  display: none;
}

.small-button .button-style {
  max-height: 30px;
}
@media only screen and (max-width: 768px) {
  .video-thumb {
    border-radius: 6px;
  }
  .video-thumb img {
    width: 300px;
    height: 162px;
    object-fit: cover;
    border-radius: 6px;
  }
}

.popover {
  z-index: 1000;
}

.clock {
  transform:scale(100%);
  transform-origin: center center;
}

.static-intro {
  background-color: #000039;
  height: 100vh;
  max-height: 760px;
}

.static-intro h3 {
  font-size: 15.5px;
}

@media only screen and (max-width: 820px) {
  .clock {
    transform:scale(75%);
    transform-origin: center center;
  }
  .static-intro {
    background-color: #000039;
    height: 91vh;
    max-height: 100vh;
  }
}



#terms ol, ul {
  margin: 0 0 0 15px;
  padding: 0;
}

#terms ol li {
  list-style-type: numbers;
  padding-bottom: 6px;
}

#terms ul li {
  list-style-type: disc;
}

.table-fade {
  opacity: .3 !important;
  filter: grayscale(100%)  !important;
}

@media only screen and (max-width: 768px) {
  .table-div {
    overflow-x: scroll;
    padding-bottom: 12px;
  }
  table {
    width: 590px !important;
  }
  table td {
    width: 75px !important;
  }
  table td:first-child {
    width: 160px !important;
  }
}

/****************************************
*
*   Live
*
****************************************/


.pulsate-fwd {
  -webkit-animation: heartbeat 2s ease-in-out 2s infinite both;
                        animation: heartbeat 2s ease-in-out 2s infinite both;
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

h1 strong, h2 strong, h3 strong,
h1 b, h2 b, h3 b {
  color: #9100d4;
  font-family: 'MarkPro-Black SKY';
  font-weight: normal;
}

.tech-cols ul {
  margin: 0;
  padding: 0;
}

.tech-cols ul li{
  margin: 0;
  padding: 0;
}

.tech-cols a {
  color: blue;
  text-decoration: underline;
}


/******************************
/
/   Video Accordian
/
/*****************************/

#video-accordian {
  width: 100%;
  height: 250px;
}

#video-accordian #roi-slide-track {
  display: flex;
  flex-direction: row;
}

.roi-item {
  height: 250px;
  flex: 1;
  min-width: calc((100% - 360px) / 4);
  transition: 0.25s ease-in-out;
}

div.roi-item:last-child {
  margin-right: 0px;
}
div.roi-item:first-child {
  margin-left: 0px;
}

#video-accordian.roi-default .roi-open.roi-item {
  flex: 2;
  min-width: 340px;
}

#video-accordian .roi-item .caption {
  opacity: 0;
  width: 100%;
  max-height: 0;
  margin-top: 0;
  transition: opacity 0.15s ease-in-out, max-height 0.15s ease-in-out, margin-top 0.15s ease-in-out;
}

#video-accordian.roi-default .roi-open.roi-item .caption {
  opacity: 1;
  max-height: 250px;
  margin-top: 20px;
  transition: opacity 0.25s ease-in-out 0.35s, max-height 0.25s ease-in-out 0.25s, margin-top 0.25s ease-in-out 0.25s;
}
#video-accordian.roi-default .roi-open.roi-item h3 {
  transition: 0.25s ease-in-out;
}

#video-accordian.roi-default .roi-open.roi-item h3 {
  font-size: 24px;
}

#video-accordian .brightcove-react-player-loader {
  position: absolute;
  top: 0;
  left:0;
  right: 0;
  bottom: 0;
  min-width:  calc(40% - 4px);
  height: 250px !important;
}

#video-accordian .brightcove-react-player-loader video-js {
  min-width:  calc(40% - 2px);
  height: 250px;
  border-radius: 0.3rem 0.3rem 0 0;
  min-height: 250px !important;
  width: 100%;
}

#video-accordian .brightcove-react-player-loader video-js .vjs-control-bar,
#video-accordian .brightcove-react-player-loader video-js .vjs-control-bar,
#video-accordian .brightcove-react-player-loader video-js .vjs-big-play-button {
  display: none;
}

#video-accordian .brightcove-react-player-loader video-js video {
  width: 100%;
  height: 100%;
  min-height: 250px !important;
  object-fit: cover;
}

@media only screen and (max-width: 930px) {
  #video-accordian {
    width: 100%;
    padding: 0 0 0 20px;
    overflow: hidden;
    overflow-x: scroll;
    scrollbar-width: none;
  }
  #video-accordian .roi-item,
  #video-accordian.roi-default .roi-item,
  #video-accordian.roi-default .roi-open.roi-item {
    height: 250px;
    width: 340px;
    flex: none;
  }

  #video-accordian .roi-item h3 {
    font-size: 24px;
  }

  #video-accordian .roi-item .caption {
    opacity: 1;
    max-height: 300px;
    margin-top: 20px;
    transition: opacity 0.25s ease-in-out 0.35s, max-height 0.25s ease-in-out 0.25s, margin-top 0.25s ease-in-out 0.25s;
  }
  #video-accordian #roi-slide-track {
    width: 500% !important;
    height: 250px;
  }
}


.h3-black-blue {
  color: #00013A !important;
  font-family: 'MarkPro-Bold SKY' !important;
}

.h3-black-blue b {
  color: #00B1EB !important;
  font-family: 'MarkPro-Bold SKY' !important;
}

.extras-description {
  font-size: 24px;
  font-family: 'MarkPro-Book SKY' !important;
}

.extras-description p {
  padding-bottom: 10px;
}

.extras-description b,
.extras-description strong {
  font-size: 16px;
  font-family: 'MarkPro-Book SKY' !important;
  font-weight: normal;
}

.extras-description img {

}

@media only screen and (max-width: 930px) {
  .extras-description {
    font-size: 18px;
    font-family: 'MarkPro-Book SKY' !important;
  }
  
  .extras-description b,
  .extras-description strong {
    font-size: 12px;
    font-family: 'MarkPro-Book SKY' !important;
    font-weight: normal;
    line-height: 14px;
  }

  .h3-black-blue b {
    display: block;
  }
}

/**********************************
    SLICK SLIDER DOTS
**********************************/

.landing-banner .slick-slider {
    padding-bottom: 30px;
}

.landing-banner .slick-dots{
    bottom: 110px !important;
}

.landing-banner .slick-dots button::before{
    height: 20px;
    width: 20px;
    display: inline-block;
    border-radius: 10px;
    background: transparent !important;
    opacity: 1 !important;
    border: 2px solid #FFFFFF !important;
    color: transparent !important;
    margin: 0 10px !important;

}
.landing-banner .slick-dots .slick-active button::before{
    background: #FFFFFF !important;
    color: #FFFFFF !important;
}

/**********************************
    SLICK SLIDER DOTS
**********************************/

.landing-features .slick-slider {
    padding-bottom: 30px;
}

.landing-features .slick-dots {
    background: #ebebeb;
    border-radius: 25px;
    bottom: -40px !important;
    padding: 10px 0px 14px 0px !important;
    display: block !important;
    max-width: 140px !important;
    text-align: center !important;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
}

.landing-features .slick-dots button::before{
    height: 20px;
    width: 20px;
    display: inline-block;
    border-radius: 10px;
    background: #b7b7b7 !important;
    opacity: 1 !important;
    color: #b7b7b7 !important;
    margin: 0 0 10px 0 !important;
    font-size: 0;

}
.landing-features .slick-dots .slick-active button::before{
    background: #636363 !important;
    color: #636363 !important;
}

@media only screen and (max-width: 500px) {
  .landing-features .slick-dots button::before{
      height: 10px;
      width: 10px;
      border-radius: 10px;
      margin: 0 0 6px 0 !important;
  }
  .landing-features .slick-dots {
    padding: 6px 0px 0px 0px !important;
    max-width: 130px !important;
}
}